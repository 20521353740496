<template>
  <a-layout-sider
    width="350"
    :style="{ overflow: 'auto', left: 0, top: 0, bottom: 0, background: '#fff', height: '100%' }"
    v-model="collapsed"
    collapsible
  >
    <a-menu
      mode="inline"
      :style="{ height: '100%', flex: 'none' }"
      :selected-keys="selectedKeys"
      :open-keys="openKeys"
      :inline-collapsed="collapsed"
      @openChange="onOpenChange"
    >
      <a-menu-item key="home">
        <router-link to="/">
          <a-icon type="home" />
          <span v-text="$t('global.menu.home')">Home</span>
        </router-link>
      </a-menu-item>
      <a-sub-menu key="admin" v-if="hasAnyAuthority('ROLE_ADMIN') && authenticated">
        <template #title>
          <span>
            <a-icon type="setting" />
            <span class="no-bold" v-text="$t('global.menu.admin.main')">Administration</span>
          </span>
        </template>
        <a-menu-item key="user-management">
          <router-link to="/admin/user-management">
            <a-icon type="team" />
            <span v-text="$t('global.menu.admin.userManagement')">User management</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="functional">
          <router-link to="/admin/functional">
            <a-icon type="apartment" />
            <span v-text="$t('global.menu.admin.functional')">Functional</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="authority-functional">
          <router-link to="/admin/authority-functional">
            <a-icon type="usergroup-add" />
            <span v-text="$t('global.menu.admin.authorityFunctional')">Authority functional</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="feedback-category">
          <router-link to="/admin/feedback-category">
            <a-icon type="read" />
            <span v-text="$t('global.menu.admin.feedbackCategory')">Feedback Category</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="school" v-if="hasAnyFunctional('0002') && authenticated">
        <template #title>
          <span>
            <a-icon type="bank" />
            <span class="no-bold" v-text="$t('global.menu.school.main')">School configuration</span>
          </span>
        </template>
        <a-menu-item key="school-year" v-if="hasAnyFunctional('000201')">
          <router-link to="/school-year">
            <a-icon type="calendar" />
            <span v-text="$t('global.menu.school.schoolYear')">School year</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="semester" v-if="hasAnyFunctional('000202')">
          <router-link to="/semester">
            <a-icon type="calendar" />
            <span v-text="$t('global.menu.school.semester')">Semester</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="school-level" v-if="hasAnyFunctional('000203')">
          <router-link to="/school-level">
            <a-icon type="branches" />
            <span v-text="$t('global.menu.school.schoolLevel')">School level</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="course" v-if="hasAnyFunctional('000204')">
          <router-link to="/course">
            <a-icon type="profile" />
            <span v-text="$t('global.menu.school.course')">Course</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="class-room" v-if="hasAnyFunctional('000205')">
          <router-link to="/class-room">
            <a-icon type="apartment" />
            <span v-text="$t('global.menu.school.classRoom')">Class room</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="contact-hour" v-if="hasAnyFunctional('000206')">
          <router-link to="/contact-hour">
            <a-icon type="calculator" />
            <span v-text="$t('global.menu.school.contactHour')">Contact hour</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="subject" v-if="hasAnyFunctional('000207')">
          <router-link to="/subject">
            <a-icon type="read" />
            <span v-text="$t('global.menu.school.subject')">Subject</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="holiday-schedule" v-if="hasAnyFunctional('000208')">
          <router-link to="/holiday-schedule">
            <a-icon type="calendar" />
            <span v-text="$t('global.menu.school.holidaySchedule')">Holiday schedule</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="score" v-if="hasAnyFunctional('0003') && authenticated">
        <template #title>
          <span>
            <a-icon type="account-book" />
            <span class="no-bold" v-text="$t('global.menu.score.main')">Score</span>
          </span>
        </template>
        <a-menu-item key="scoring-scale" v-if="hasAnyFunctional('000301')">
          <router-link to="/scoring-scale">
            <a-icon type="container" />
            <span v-text="$t('global.menu.score.scoringScale')">Scoring scale</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="scoring-type" v-if="hasAnyFunctional('000302')">
          <router-link to="/scoring-type">
            <a-icon type="container" />
            <span v-text="$t('global.menu.score.scoringType')">Scoring type</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="score-group" v-if="hasAnyFunctional('000303')">
          <router-link to="/score-group">
            <a-icon type="container" />
            <span v-text="$t('global.menu.score.scoreGroup')">Score group</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="subject-score" v-if="hasAnyFunctional('000304')">
          <router-link to="/subject-score">
            <a-icon type="container" />
            <span v-text="$t('global.menu.score.subjectScore')">Subject score</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="education-department-score" v-if="hasAnyFunctional('000307')">
          <router-link to="/education-department-score">
            <a-icon type="container" />
            <span v-text="$t('global.menu.score.educationDepartmentScore')">Education department score</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="import-score" v-if="hasAnyFunctional('000305')">
          <router-link to="/import-score">
            <a-icon type="container" />
            <span v-text="$t('global.menu.score.importScore')">Import score</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="score-lock" v-if="hasAnyFunctional('000306')">
          <router-link to="/score-lock">
            <a-icon type="stop" />
            <span v-text="$t('global.menu.score.scoreLock')">Scores lock</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="timetables" v-if="hasAnyFunctional('0004') && authenticated">
        <template #title>
          <span>
            <a-icon type="schedule" />
            <span class="no-bold" v-text="$t('global.menu.timetable.main')">Timetable</span>
          </span>
        </template>
        <a-menu-item key="teaching-assignment" v-if="hasAnyFunctional('000401')">
          <router-link to="/teaching-assignment">
            <a-icon type="solution" />
            <span v-text="$t('global.menu.timetable.teachingAssignment')">Teaching assignment</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="timetable" v-if="hasAnyFunctional('000402')">
          <router-link to="/timetable">
            <a-icon type="table" />
            <span v-text="$t('global.menu.timetable.timetable')">Timetable</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="timetable-teacher" v-if="hasAnyFunctional('000405')">
          <router-link to="/timetable-teacher">
            <a-icon type="table" />
            <span v-text="$t('global.menu.timetable.timetableTeacher')">Timetable teacher</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="lecture-schedule" v-if="hasAnyFunctional('000403')">
          <router-link to="/lecture-schedule">
            <a-icon type="container" />
            <span v-text="$t('global.menu.timetable.lectureSchedule')">Lecture schedule</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="teaching-schedule" v-if="hasAnyFunctional('000404')">
          <router-link to="/teaching-schedule">
            <a-icon type="carry-out" />
            <span v-text="$t('global.menu.timetable.teachingSchedule')">Teaching schedule</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="additional-teaching-schedule" v-if="hasAnyFunctional('000404')">
          <router-link to="/additional-teaching-schedule">
            <a-icon type="carry-out" />
            <span v-text="$t('global.menu.timetable.additionalTeachingSchedule')">Additional teaching schedule</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="student-and-teacher" v-if="hasAnyFunctional('0005') && authenticated">
        <template #title>
          <span>
            <a-icon type="contacts" />
            <span class="no-bold" v-text="$t('global.menu.studentAndTeacher.main')">Student and teacher</span>
          </span>
        </template>
        <a-menu-item key="student" v-if="hasAnyFunctional('000501')">
          <router-link to="/student">
            <a-icon type="user-add" />
            <span v-text="$t('global.menu.studentAndTeacher.student')">Student</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="teacher" v-if="hasAnyFunctional('000502')">
          <router-link to="/teacher">
            <a-icon type="user-add" />
            <span v-text="$t('global.menu.studentAndTeacher.teacher')">Teacher</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="feedback" v-if="hasAnyFunctional('000503')">
          <router-link to="/feedback">
            <a-icon type="mail" />
            <span v-text="$t('global.menu.studentAndTeacher.feedback')">Feedback</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="kitchen" v-if="hasAnyFunctional('0006') && authenticated">
        <template #title>
          <span>
            <a-icon type="coffee" />
            <span class="no-bold" v-text="$t('global.menu.kitchen.main')">Kitchen</span>
          </span>
        </template>
        <a-menu-item key="price" v-if="hasAnyFunctional('000601')">
          <router-link to="/price">
            <a-icon type="dollar" />
            <span v-text="$t('global.menu.kitchen.price')">Price</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="order-time" v-if="hasAnyFunctional('000602')">
          <router-link to="/order-time">
            <a-icon type="clock-circle" />
            <span v-text="$t('global.menu.kitchen.orderTime')">Order time</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="menu" v-if="hasAnyFunctional('000603')">
          <router-link to="/menu">
            <a-icon type="menu" />
            <span v-text="$t('global.menu.kitchen.menu')">Menu</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="order" v-if="hasAnyFunctional('000604')">
          <router-link to="/order">
            <a-icon type="shopping-cart" />
            <span v-text="$t('global.menu.kitchen.order')">Order</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="order-delete" v-if="hasAnyFunctional('000606')">
          <router-link to="/order-delete">
            <a-icon type="shopping-cart" />
            <span v-text="$t('global.menu.kitchen.orderDelete')">Order delete</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="statistics" v-if="hasAnyFunctional('000605')">
          <router-link to="/statistics">
            <a-icon type="pie-chart" />
            <span v-text="$t('global.menu.kitchen.statistics')">Statistics</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="finance" v-if="hasAnyFunctional('0007') && authenticated">
        <template #title>
          <span>
            <a-icon type="dollar" />
            <span class="no-bold" v-text="$t('global.menu.finance.main')">Finance</span>
          </span>
        </template>
        <a-menu-item key="payment-category" v-if="hasAnyFunctional('000701')">
          <router-link to="/payment-category">
            <a-icon type="credit-card" />
            <span v-text="$t('global.menu.finance.paymentCategory')">Payment category</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="invoice" v-if="hasAnyFunctional('000702')">
          <router-link to="/invoice">
            <a-icon type="file-done" />
            <span v-text="$t('global.menu.finance.invoice')">Invoice</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="notifications" v-if="hasAnyFunctional('0008') && authenticated">
        <template #title>
          <span>
            <a-icon type="alert" />
            <span class="no-bold" v-text="$t('global.menu.notification.main')">Notification</span>
          </span>
        </template>
        <a-menu-item key="notification" v-if="hasAnyFunctional('000801')">
          <router-link to="/notification">
            <a-icon type="carry-out" />
            <span v-text="$t('global.menu.notification.timekeepingNotification')">Timekeeping notification</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="school-notification" v-if="hasAnyFunctional('000802')">
          <router-link to="/school-notification">
            <a-icon type="notification" />
            <span v-text="$t('global.menu.notification.schoolNotification')">School notification</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="notification-control" v-if="hasAnyFunctional('000803')">
          <router-link to="/notification-control">
            <a-icon type="control" />
            <span v-text="$t('global.menu.notification.notificationControl')">Notification control</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="attendance-time" v-if="hasAnyFunctional('000804')">
          <router-link to="/attendance-time">
            <a-icon type="clock-circle" />
            <span v-text="$t('global.menu.notification.attendanceTime')">Attendance time</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="other-student-notification" v-if="hasAnyFunctional('000805')">
          <router-link to="/other-student-notification">
            <a-icon type="message" />
            <span v-text="$t('global.menu.notification.otherStudentNotification')">other student notification</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="notification-teacher" v-if="hasAnyFunctional('000806')">
          <router-link to="/notification-teacher">
            <a-icon type="schedule" />
            <span v-text="$t('global.menu.notification.notificationTeacher')">Notification teacher</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="report" v-if="hasAnyFunctional('0009') && authenticated">
        <template #title>
          <span>
            <a-icon type="area-chart" />
            <span class="no-bold" v-text="$t('global.menu.report.main')">Report</span>
          </span>
        </template>
        <a-menu-item key="score-report-teacher" v-if="hasAnyFunctional('000901')">
          <router-link to="/score-report-teacher">
            <a-icon type="file-excel" />
            <span v-text="$t('global.menu.report.scoreReportTeacher')">Score report teacher</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="reward-and-discipline-report" v-if="hasAnyFunctional('000902')">
          <router-link to="/reward-and-discipline-report">
            <a-icon type="file-excel" />
            <span v-text="$t('global.menu.report.rewardAndDisciplineReport')">rewardAndDisciplineReport</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="subjectScoreReport" v-if="hasAnyFunctional('000903')">
          <router-link to="">
            <a-icon type="file-excel" />
            <span v-text="$t('global.menu.report.subjectScoreReport')">subjectScoreReport</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="parentFeedbackReport" v-if="hasAnyFunctional('000904')">
          <router-link to="">
            <a-icon type="file-excel" />
            <span v-text="$t('global.menu.report.parentFeedbackReport')">parentFeedbackReport</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="teacherFeedbackReport" v-if="hasAnyFunctional('000905')">
          <router-link to="">
            <a-icon type="file-excel" />
            <span v-text="$t('global.menu.report.teacherFeedbackReport')">teacherFeedbackReport</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="unfulfilled-teaching-schedule-report" v-if="hasAnyFunctional('000906')">
          <router-link to="/unfulfilled-teaching-schedule-report">
            <a-icon type="file-excel" />
            <span v-text="$t('global.menu.report.unfulfilledTeachingScheduleReport')">unfulfilledTeachingScheduleReport</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="timetable-all-course-report" v-if="hasAnyFunctional('000907')">
          <router-link to="/timetable-all-course-report">
            <a-icon type="file-excel" />
            <span v-text="$t('global.menu.report.timetableAllCourse')">timetableAllCourse</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="website" v-if="hasAnyFunctional('0011') && authenticated">
        <template #title>
          <span>
            <a-icon type="ie" />
            <span class="no-bold" v-text="$t('global.menu.website.main')">Website</span>
          </span>
        </template>
        <a-menu-item key="news-category" v-if="hasAnyFunctional('001101')">
          <router-link to="/news-category">
            <a-icon type="unordered-list" />
            <span v-text="$t('global.menu.website.newsCategory')">News category</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="tag" v-if="hasAnyFunctional('001102')">
          <router-link to="/tag">
            <a-icon type="tags" />
            <span v-text="$t('global.menu.website.tag')">Tag</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="news" v-if="hasAnyFunctional('001103')">
          <router-link to="/news">
            <a-icon type="read" />
            <span v-text="$t('global.menu.website.news')">News</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="home-page" v-if="hasAnyFunctional('001104')">
          <router-link to="/home-page">
            <a-icon type="read" />
            <span v-text="$t('global.menu.website.homePage')">Home Page</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </a-layout-sider>
</template>

<script lang="ts" src="./jhi-sidebar.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ant-menu-item > a {
  font-weight: normal;
}
</style>
